import Sreedevi from '../Assets/img/Team/Sreedevi.jpg';
import Pathmanaban from '../Assets/img/Team/Pathmanaban.jpg';
import Mathupriya from '../Assets/img/Team/Mathupriya.jpg';
import EbishdonGv from '../Assets/img/Team/EBISHDON_G_V.png';
import SacheinD from '../Assets/img/Team/SACHEIN_D.png';
import AbhishekM from '../Assets/img/Team/ABHISHEK_M.png';
import AshwinSujeshS from '../Assets/img/Team/ASHWIN_SUJESH_S.png';
import IndhuPriyadharshiniS from '../Assets/img/Team/INDHU_PRIYADHARSHINI_S.png';
import Krithika from '../Assets/img/Team/KRITHIKA.png';
import Bhaargav from '../Assets/img/Team/bhaargav.jpg';
import Balaji from '../Assets/img/Team/Balaji_V_S.jpg';
import MosuruAbilash from '../Assets/img/Team/MOSURU_ABILASH.png';
import VasanthR from '../Assets/img/Team/VASANTH_R.png';
import WasiqRahmanMA from '../Assets/img/Team/WASIQ_RAHMAN_M_A.png';
import AruneshwarUS from '../Assets/img/Team/ARUNESHWAR_U_S.jpg';
import JayaramakrishnaD from '../Assets/img/Team/JAYARAMAKRISHNA_D.png';
import DeenuPN from '../Assets/img/Team/DEENU_P_N.png';
import JanaharS from '../Assets/img/Team/JANAHAR_S.png';
import HariPranavaM from '../Assets/img/Team/HARI_PRANAVA_M.png';
import DuraiDayananth from '../Assets/img/Team/DURAI_DAYANANTH.png';
import LamishaA from '../Assets/img/Team/LAMISHA_A.png';
import SwathiGoswami from '../Assets/img/Team/SWATHI_GOSWAMI.png';
import KeerthanaG from '../Assets/img/Team/KEERTHANA_G.png';
import BathriNarayanV from '../Assets/img/Team/BATHRI_NARAYAN_V.png';
import SwapnaA from '../Assets/img/Team/SWAPNA_A.png';
import Viveka from '../Assets/img/Team/Viveka.png';
import PonvaishnaviGanesh from '../Assets/img/Team/Vaishnavi_Ganesh.png';
import PavithraR from '../Assets/img/Team/PAVITHRA_R.png';
import MonicaK from '../Assets/img/Team/MONICA_K.png';
import MadhavTejas from '../Assets/img/Team/Madhav_tejas.png';
import DharaniM from '../Assets/img/Team/DHARANI_M.png';
import PavithraS from '../Assets/img/Team/PAVITHRA_S.png';
import JawaharJ from '../Assets/img/Team/JAWAHAR_J.png';
import SibisuryaS from '../Assets/img/Team/SIBISURYA_S.png';
import NitheeshSrinivaasan from '../Assets/img/Team/Nitheesh_Srinivaasan.png';
import HariharanS from '../Assets/img/Team/HARIHARAN_S.png';
import MohamedRazeen from '../Assets/img/Team/MOHAMED_RAZEEN.png';
import ShivaPrasathR from '../Assets/img/Team/SHIVA_PRASATH_R.png';
import HarishS from '../Assets/img/Team/HARISH_S.png';
import RickyDavisJ from '../Assets/img/Team/RICKY_DAVIS_J.jpg';
import NandaKumarRP from '../Assets/img/Team/NANDA_KUMAR_R_P.png';
import SedhumadhavanK from '../Assets/img/Team/SEDHUMADHAVAN_K.png';
import AnujKrishnaJM from '../Assets/img/Team/ANUJ_KRISHNA_J_M.png';
import Aishwarya from '../Assets/img/Team/AISWARYA_S_M.jpg';
import YuvaneshP from '../Assets/img/Team/YUVANESH_P.png';
import BhargavSrikumar from '../Assets/img/Team/Bhargav_Srikumar.png';
import AswanthT from '../Assets/img/Team/ASWANTH_T.png';
import Vignesh from '../Assets/img/Team/VIGNESH_A_K.png';
import Adhirooban from '../Assets/img/Team/ADHIROOBAN_D.png';
import Shrivarshini from '../Assets/img/Team/SHRIVARSHINI_G.png';
import NithyaSree from '../Assets/img/Team/NITHYA_SREE_P.png';
import DivyaSri from '../Assets/img/Team/DIVYA_SRI_DHARSHINI_B.png';
import AnathaKarthikayan from '../Assets/img/Team/ANANTHA_KARTHIGEYAN_M_S.png';
import DharunKumar from '../Assets/img/Team/DHARUNKUMAR_S.png';
import Kirthana from '../Assets/img/Team/Kirthana_Mohan_R.png';
import DharshiniV from '../Assets/img/Team/DHARSHINI_V.png';
import LokeshvarP from '../Assets/img/Team/LOKESHVAR_P.png';
import Muthupriya from '../Assets/img/Team/Muthu_Priya_S.png';
import Amirtha from '../Assets/img/Team/AMIRTHAVARSHIINI_R.png';
import SriVarshan from '../Assets/img/Team/SRIVARSHAN_V_M.png';
import Radhika from '../Assets/img/Team/RADHIKA_V.png';
import Sridhar from '../Assets/img/Team/A_SRIDHAR.png';
import Thanyasree from '../Assets/img/Team/THANYASHREE_S_J.png';
import Shrikanth from '../Assets/img/Team/SHRIKANTH_K_S.png';
import SaiKrithika from '../Assets/img/Team/SAI_KRITHIKA_M.png';
import Vinay from '../Assets/img/Team/VINAY_B.png';
import Thiruvikraman from '../Assets/img/Team/THIRUVIKRAMAN_G_S.png';
import Pavithra from '../Assets/img/Team/PAVITHRA.png';
import Sreenidhi from '../Assets/img/Team/SREENIDHI_R.png';
import HemaShree from '../Assets/img/Team/HEMASRI_P.png';
import ArunKumar from '../Assets/img/Team/ARUN_KUMAR_T.png';
import BalajiV from '../Assets/img/Team/BALAJI_V.png';
import ThamaraiSelvan from '../Assets/img/Team/THAMARAISELVAN_V.png';
import Varshini from '../Assets/img/Team/VARSHINI_S.png';
import Kaviyanjali from '../Assets/img/Team/KAVIYANJALI_M.png';
import SimonJacob from '../Assets/img/Team/SIMON_JACOB_A.png';
import Ananthi from '../Assets/img/Team/ANANTHI_V.png';
import Rupeshwar from '../Assets/img/Team/RUPESHWAR_DURAISAMY.png';
import Siddarth from '../Assets/img/Team/SIDDHARTH_S_SWAMY.png';
import Kavinshree from '../Assets/img/Team/KAVINSHREE_V.png';
import Supriya from '../Assets/img/Team/SUPRIYA_K.png';
import UdhayaKiran from '../Assets/img/Team/UDHAYA_KIRAN_M_V.png';
import Thirumugil from '../Assets/img/Team/THIRUMUGIL_M_S.png';
import Harisharajan from '../Assets/img/Team/HARISHARAJAN_R_R.png';
import Gayathri from '../Assets/img/Team/GAYATHRI_S.png';
import Bhagyashree from '../Assets/img/Team/Bhagyashree_L.png';
import Aswin from '../Assets/img/Team/ASWIN_R.png';
import Deepika from '../Assets/img/Team/DEEPIKA_D.png';
import Krushnasayi from '../Assets/img/Team/KRUSHNASAYI_S.png';
import NaveenKrishna from '../Assets/img/Team/NAVEENKRISHNA_B_B.png';
import Charu from '../Assets/img/Team/CHARU_DHARSHANA_R.png';
import Bhoomika from '../Assets/img/Team/BHOOMIKA_V.png';
import SakthiArunachalam from '../Assets/img/Team/SAKTHI_ARUNACHALAM_S.png';
import Sharjun from '../Assets/img/Team/SHARJUN_A.png';
import LarisPeterP from '../Assets/img/Team/P_LARIS_PETER.png';
import PraveenKumar from '../Assets/img/Team/PRAVEENKUMAR_S.png';
import NaveenKumar from '../Assets/img/Team/NAVEENKUMAR_P.png';
import Nithyashree from '../Assets/img/Team/NITHYA_SREE_R.png';
import HarishDK from '../Assets/img/Team/HARISH_D_K.png';
import YathinSai from '../Assets/img/Team/BOMMISETTY_YATHIN_SAI.png';
import AbinayaR from '../Assets/img/Team/ABINAYA_R.png';
import Yogesh from '../Assets/img/Team/YOGESH_V.png';
import Gowtham from '../Assets/img/Team/GOWTHAMAN_S.png';
import AravindSuriya from '../Assets/img/Team/ARAVIND_SURYA_D.png';
import KhoshikPerumalS from '../Assets/img/Team/KHOSHIK_PERUMAL_S.png';
import SakthivelP from '../Assets/img/Team/SAKTHIVEL_P.png';
import SuhailAhmedT from '../Assets/img/Team/Suhail_Ahmad.png';
import Siddheswari from '../Assets/img/Team/SIDDHESHWARI_B.png';
import Shanmathi from '../Assets/img/Team/SHANMATHI_S.png';
import Sathasivam from '../Assets/img/Team/SATHASIVAM_R_S.png';
import SabareeshCS from '../Assets/img/Team/SABAREESH_C_S.jpg';
import VaishakD from '../Assets/img/Team/VAISHAK_D.png';
import Yuvabharathi from '../Assets/img/Team/YUVABHARATHI_J_B.png';
import Harithra from '../Assets/img/Team/Harithra.png';
import Keerthana from '../Assets/img/Team/KEERTHANA_J.png';
import Pravikash from '../Assets/img/Team/PRAVIKASH_R_K.png';
import Dharanidharan from '../Assets/img/Team/DHARANIDHARAN_R.png';
import AjithaAnanthiJ from '../Assets/img/Team/Ajitha_Ananthi_J.png';
import DharshiniNew from '../Assets/img/Team/DHARSHINI_L.png';
import NaveenKarthick from '../Assets/img/Team/NAVEEN_KARTHICK_T_K.png';
import PavithraAds from '../Assets/img/Team/Pavithra_ads.png';
import Padmasree from '../Assets/img/Team/PADMASREE_G.png';
import SridharR from '../Assets/img/Team/SRIDHAR_R.png';
import Mukunthan from '../Assets/img/Team/MUGUNTHAN_V.png';
import Esthak from '../Assets/img/Team/ESTHAK_JEROSIN_K.png';
import Harsha from '../Assets/img/Team/HARSHA_V.png';
import Sandhya from '../Assets/img/Team/SANDHYA_K_B.png';
import AbishaEunice from '../Assets/img/Team/ABISHA_EUNICE_A.png';
import SarveshrajM from '../Assets/img/Team/SARVESHRAJ_M.png';
import Abiram from '../Assets/img/Team/abiram.jpg';


export const TeamService = {
    getTeamData() {
        return [
            {
                name: 'Sreedevi',
                role: 'HOD/CSE',
                team: 'Core Ninjas',
                image: Sreedevi
            },
            {
                name: 'Pathmanaban',
                role: 'AP/CSE',
                team: 'Core Ninjas',
                image: Pathmanaban
            },
            {
                name: 'Mathupriya',
                role: 'AP/CSE',
                team: 'Core Ninjas',
                image: Mathupriya
            },
            {
                name: 'Ebishdon G V',
                role: 'Chairman',
                team: 'Core Ninjas',
                image: EbishdonGv
            },
            {
                name: 'Sachein D',
                role: 'Vice Chairman',
                team: 'Core Ninjas',
                image: SacheinD
            },
            {
                name: 'Abhishek M',
                role: 'Secretary',
                team: 'Core Ninjas',
                image: AbhishekM
            },
            {
                name: 'Ashwin Sujesh S',
                role: 'Vice Secretary',
                team: 'Core Ninjas',
                image: AshwinSujeshS
            },
            {
                name: 'Indhu Priyadharshini S',
                role: 'Treasurer',
                team: 'Core Ninjas',
                image: IndhuPriyadharshiniS
            },
            {
                name: 'Krithika',
                role: 'Vice Treasurer',
                team: 'Core Ninjas',
                image: Krithika
            },
            {
                name: 'Balaji V S',
                role: 'Web Team Lead',
                team: 'Web Developer Ninjas',
                image: Balaji
            },
             {
                name: 'Bhaargav Gupta P',
                role: 'Web Developer',
                team: 'Web Developer Ninjas',
                image: Bhaargav
            },
            {
                name: 'Mosuru Abilash',
                role: 'Web Developer',
                team: 'Web Developer Ninjas',
                image: MosuruAbilash
            },
            {
                name: 'Vasanth R',
                role: 'Web Developer',
                team: 'Web Developer Ninjas',
                image: VasanthR
            },
            {
                name: 'Wasiq Rahman M A',
                role: 'Web Developer',
                team: 'Web Developer Ninjas',
                image: WasiqRahmanMA
            },
            {
                name: 'Aruneshwar U S',
                role: 'UI/UX Designer',
                team: 'Web Design Ninjas',
                image: AruneshwarUS
            },
            {
                name: 'Janahar S',
                role: 'UI/UX Designer',
                team: 'Web Design Ninjas',
                image: JanaharS
            },
            {
                name: 'Jayaramakrishna D',
                role: 'UI/UX Designer',
                team: 'Web Design Ninjas',
                image: JayaramakrishnaD
            },
            {
                name: 'Deenu P N',
                role: 'UI/UX Designer',
                team: 'Web Design Ninjas',
                image: DeenuPN
            },
            {
                name: 'Hari Pranava M',
                role: 'UI/UX Designer',
                team: 'Web Design Ninjas',
                image: HariPranavaM
            },
            {
                name: 'Durai Dayananth',
                role: 'Content Developer',
                team: 'Content Developer Ninjas',
                image: DuraiDayananth
            },
            {
                name: 'Lamisha A',
                role: 'Content Developer',
                team: 'Content Developer Ninjas',
                image: LamishaA
            },
            {
                name: 'Swathi Goswami',
                role: 'Content Developer',
                team: 'Content Developer Ninjas',
                image: SwathiGoswami
            },
            {
                name: 'Keerthana G',
                role: 'Content Developer',
                team: 'Content Developer Ninjas',
                image: KeerthanaG
            },
            {
                name: 'Bathri Narayan V',
                role: 'Content Developer',
                team: 'Content Developer Ninjas',
                image: BathriNarayanV
            },
            {
                name: 'Aishwarya S M',
                role: 'Overall Event Coordinator',
                team: 'Overall Event Coordinator Ninja',
                image: Aishwarya
            },
            {
                name: 'Nitheesh Srinivaasan',
                role: 'Office Bearer',
                team: 'Office Bearer Ninjas',
                image: NitheeshSrinivaasan
            },
            {
                name: 'Swapna A',
                role: 'Office Bearer',
                team: 'Office Bearer Ninjas',
                image: SwapnaA
            },
            {
                name: 'Viveka',
                role: 'Office Bearer',
                team: 'Office Bearer Ninjas',
                image: Viveka
            },
            {
                name: 'Ponvaishnavi Ganesh',
                role: 'Office Bearer',
                team: 'Office Bearer Ninjas',
                image: PonvaishnaviGanesh
            },
            {
                name: 'Pavithra R',
                role: 'Office Bearer',
                team: 'Office Bearer Ninjas',
                image: PavithraR
            },
            {
                name: 'Monica K',
                role: 'Office Bearer',
                team: 'Office Bearer Ninjas',
                image: MonicaK
            },
            {
                name: 'Madhav Tejas',
                role: 'Office Bearer',
                team: 'Office Bearer Ninjas',
                image: MadhavTejas
            },
            {
                name: 'Dharani M',
                role: 'Office Bearer',
                team: 'Office Bearer Ninjas',
                image: DharaniM
            },
            {
                name: 'Pavithra S',
                role: 'Office Bearer',
                team: 'Office Bearer Ninjas',
                image: PavithraS
            },
            {
                name: 'Jawahar J',
                role: 'Office Bearer',
                team: 'Office Bearer Ninjas',
                image: JawaharJ
            },
            {
                name: 'Sibisurya S',
                role: 'Office Bearer',
                team: 'Office Bearer Ninjas',
                image: SibisuryaS
            },
            {
                name: 'Hariharan S',
                role: 'Office Bearer',
                team: 'Office Bearer Ninjas',
                image: HariharanS
            },
            {
                name: 'Mohamed Razeen',
                role: 'Office Bearer',
                team: 'Office Bearer Ninjas',
                image: MohamedRazeen
            },
            {
                name: 'Shiva Prasath R',
                role: 'Office Bearer',
                team: 'Office Bearer Ninjas',
                image: ShivaPrasathR
            },
            {
                name: 'Harish S',
                role: 'Video Editor',
                team: 'Video Editing Ninjas',
                image: HarishS
            },
            {
                name: 'Ricky Davis J',
                role: 'Video Editor',
                team: 'Video Editing Ninjas',
                image: RickyDavisJ
            },
            {
                name: 'Nanda Kumar R P',
                role: 'Video Editor',
                team: 'Video Editing Ninjas',
                image: NandaKumarRP
            },
            {
                name: 'Sarveshraj M',
                role: 'Video Editor',
                team: 'Video Editing Ninjas',
                image: SarveshrajM
            },
            {
                name: 'Anuj Krishna J M',
                role: 'Video Editor',
                team: 'Video Editing Ninjas',
                image: AnujKrishnaJM
            },
            {
                name: 'Yuvanesh P',
                role: 'Digital Influencer',
                team: 'Social Media Ninjas',
                image: YuvaneshP
            },
            {
                name: 'Bhargav Srikumar',
                role: 'Digital Influencer',
                team: 'Social Media Ninjas',
                image: BhargavSrikumar
            },
            {
                name: 'Sedhumadhavan K',
                role: 'Digital Influencer',
                team: 'Social Media Ninjas',
                image: SedhumadhavanK
            },
            {
                name: 'Aswanth T',
                role: 'Digital Influencer',
                team: 'Social Media Ninjas',
                image: AswanthT
            },
            // Technical Events
            {
                name: 'Vignesh A K',
                role: 'Event coordinator',
                team: 'QUIRK CLASH: PRO VS FOE Ninjas',
                image: Vignesh
            },
            {
                name: 'Adhirooban',
                role: 'Event coordinator',
                team: 'QUIRK CLASH: PRO VS FOE Ninjas',
                image: Adhirooban
            },
            {
                name: 'Shrivarshini',
                role: 'Event coordinator',
                team: 'QUIRK CLASH: PRO VS FOE Ninjas',
                image: Shrivarshini
            },
            {
                name: 'Nithya Sree',
                role: 'Event coordinator',
                team: 'QUIRK CLASH: PRO VS FOE Ninjas',
                image: NithyaSree
            },
            {
                name: 'Divya Sri',
                role: 'Event coordinator',
                team: 'QUIRK CLASH: PRO VS FOE Ninjas',
                image: DivyaSri
            },
            {
                name: 'Anatha Karthikayan',
                role: 'Event coordinator',
                team: 'Dungeon Tech Quiz Ninjas',
                image: AnathaKarthikayan 
            },
            {
                name: 'Dharun Kumar',
                role: 'Event coordinator',
                team: 'Dungeon Tech Quiz Ninjas',
                image: DharunKumar 
            },
            {
                name: 'Kirthana',
                role: 'Event coordinator',
                team: 'Dungeon Tech Quiz Ninjas',
                image: Kirthana
            },
            {
                name: 'Dharshini V',
                role: 'Event coordinator',
                team: 'Dungeon Tech Quiz Ninjas',
                image: DharshiniV 
            },
            {
                name: 'Lokeshvar P',
                role: 'Event coordinator',
                team: 'Dungeon Tech Quiz Ninjas',
                image: LokeshvarP
            },
            {
                name: 'Muthupriya',
                role: 'Event coordinator',
                team: 'Zanpakuto capture Ninjas',
                image: Muthupriya
            },
            {
                name: 'Amirtha',
                role: 'Event coordinator',
                team: 'Zanpakuto capture Ninjas',
                image: Amirtha
            },
            {
                name: 'Sri Varshan V M',
                role: 'Event coordinator',
                team: 'Zanpakuto capture Ninjas',
                image: SriVarshan
            },
            {
                name: 'Radhika V',
                role: 'Event coordinator',
                team: 'Zanpakuto capture Ninjas',
                image: Radhika
            },
            {
                name: 'Sridhar A',
                role: 'Event coordinator',
                team: 'Zanpakuto capture Ninjas',
                image: Sridhar
            },
            // Pitch With Power Team
            {
                name: 'Thanyasree S J',
                role: 'Event Coordinator',
                team: 'Pitch With Power Ninjas',
                image: Thanyasree
            },
            {
                name: 'Shrikanth',
                role: 'Event Coordinator',
                team: 'Pitch With Power Ninjas',
                image: Shrikanth
            },
            {
                name: 'Sai Krithika M',
                role: 'Event Coordinator',
                team: 'Pitch With Power Ninjas',
                image: SaiKrithika
            },
            {
                name: 'Vinay',
                role: 'Event Coordinator',
                team: 'Pitch With Power Ninjas',
                image: Vinay
            },
            {
                name: 'Thiruvikraman GS',
                role: 'Event Coordinator',
                team: 'Pitch With Power Ninjas',
                image: Thiruvikraman
            },
            {
                name: 'Pavithra R',
                role: 'Event Coordinator',
                team: 'Pitch With Power Ninjas',
                image: Pavithra
            },

            // Pirate’s Idea Quest Team
            {
                name: 'Sreenidhi',
                role: 'Event Coordinator',
                team: "Pirate’s Idea Quest Ninjas",
                image: Sreenidhi
            },
            {
                name: 'Hema Shree',
                role: 'Event Coordinator',
                team: "Pirate’s Idea Quest Ninjas",
                image: HemaShree
            },
            {
                name: 'ARUN KUMAR T',
                role: 'Event Coordinator',
                team: "Pirate’s Idea Quest Ninjas",
                image: ArunKumar
            },
            {
                name: 'Balaji V',
                role: 'Event Coordinator',
                team: "Pirate’s Idea Quest Ninjas",
                image: BalajiV
            },
            {
                name: 'ThamaraiSelvan',
                role: 'Event Coordinator',
                team: "Pirate’s Idea Quest Ninjas",
                image: ThamaraiSelvan
            },

            // Shinobi's Design Quest Team
            {
                name: 'Varshini',
                role: 'Event Coordinator',
                team: "Shinobi's Design Quest Ninjas",
                image: Varshini
            },
            {
                name: 'Kaviyanjali',
                role: 'Event Coordinator',
                team: "Shinobi's Design Quest Ninjas",
                image: Kaviyanjali
            },
            {
                name: 'Simon Jacob',
                role: 'Event Coordinator',
                team: "Shinobi's Design Quest Ninjas",
                image: SimonJacob
            },
            {
                name: 'Ananthi',
                role: 'Event Coordinator',
                team: "Shinobi's Design Quest Ninjas",
                image: Ananthi
            },
            {
                name: 'RUPESHWAR DURAISAMY',
                role: 'Event Coordinator',
                team: "Shinobi's Design Quest Ninjas",
                image: Rupeshwar
            },

            // Stone Age Relay Team
            {
                name: 'Siddarth',
                role: 'Event Coordinator',
                team: 'Stone Age Relay Ninjas',
                image: Siddarth
            },
            {
                name: 'Kavinshree',
                role: 'Event Coordinator',
                team: 'Stone Age Relay Ninjas',
                image: Kavinshree
            },
            {
                name: 'Supriya',
                role: 'Event Coordinator',
                team: 'Stone Age Relay Ninjas',
                image: Supriya
            },
            {
                name: 'Udhaya Kiran',
                role: 'Event Coordinator',
                team: 'Stone Age Relay Ninjas',
                image: UdhayaKiran
            },
            {
                name: 'Thirumugil M S',
                role: 'Event Coordinator',
                team: 'Stone Age Relay Ninjas',
                image: Thirumugil
            },

            // Shadow Code Team
            {
                name: 'Harisharajan',
                role: 'Event Coordinator',
                team: 'Shadow Code Ninjas',
                image: Harisharajan
            },
            {
                name: 'Abiram R',
                role: 'Event Coordinator',
                team: 'Shadow Code Ninjas',
                image: Abiram
            },
            {
                name: 'Gayathri',
                role: 'Event Coordinator',
                team: 'Shadow Code Ninjas',
                image: Gayathri
            },
            {
                name: 'Bhagyashree L',
                role: 'Event Coordinator',
                team: 'Shadow Code Ninjas',
                image: Bhagyashree
            },
            {
                name: 'Aswin R',
                role: 'Event Coordinator',
                team: 'Shadow Code Ninjas',
                image: Aswin
            },
            {
                name: 'D DEEPIKA',
                role: 'Event Coordinator',
                team: 'Shadow Code Ninjas',
                image: Deepika
            },

            // Non-Technical Events
            {
                name: 'Krushnasayi',
                role: 'Event coordinator',
                team: 'Sharingan Pixels Ninjas',
                image: Krushnasayi
            },
            {
                name: 'Naveen Krishna',
                role: 'Event coordinator',
                team: 'Sharingan Pixels Ninjas',
                image: NaveenKrishna
            },
            {
                name: 'Charu',
                role: 'Event coordinator',
                team: 'Sharingan Pixels Ninjas',
                image: Charu
            },
            {
                name: 'Bhoomika',
                role: 'Event coordinator',
                team: 'Sharingan Pixels Ninjas',
                image: Bhoomika
            },
            {
                name: 'Sakthi Arunachalam',
                role: 'Event coordinator',
                team: 'Tokyo Bid Showdown Ninjas',
                image: SakthiArunachalam
            },
            {
                name: 'Sharjun',
                role: 'Event coordinator',
                team: 'Tokyo Bid Showdown Ninjas',
                image: Sharjun
            },
            {
                name: 'Laris Peter P',
                role: 'Event coordinator',
                team: 'Tokyo Bid Showdown Ninjas',
                image: LarisPeterP
            },
            {
                name: 'Praveen Kumar',
                role: 'Event coordinator',
                team: 'Tokyo Bid Showdown Ninjas',
                image: PraveenKumar
            },
            {
                name: 'Naveen Kumar',
                role: 'Event coordinator',
                team: 'Tokyo Bid Showdown Ninjas',
                image: NaveenKumar
            },
            {
                name: 'Nithyashree',
                role: 'Event coordinator',
                team: 'Slayer Snapshot Ninjas',
                image: Nithyashree
            },
            {
                name: 'Harish D K',
                role: 'Event coordinator',
                team: 'Slayer Snapshot Ninjas',
                image: HarishDK
            },
            {
                name: 'Yathin Sai',
                role: 'Event coordinator',
                team: 'Slayer Snapshot Ninjas',
                image: YathinSai
            },
            {
                name: 'Abinaya R',
                role: 'Event coordinator',
                team: 'Slayer Snapshot Ninjas',
                image: AbinayaR
            },
            {
                name: 'Yogesh',
                role: 'Event coordinator',
                team: 'Slayer Snapshot Ninjas',
                image: Yogesh
            },
            {
                name: 'Gowtham',
                role: 'Event coordinator',
                team: 'Shibuya Survival Clash Ninjas',
                image: Gowtham
            },
            {
                name: 'Aravind Suriya',
                role: 'Event coordinator',
                team: 'Shibuya Survival Clash Ninjas',
                image: AravindSuriya
            },
            {
                name: 'Khoshik Perumal S',
                role: 'Event coordinator',
                team: 'Shibuya Survival Clash Ninjas',
                image: KhoshikPerumalS
            },
            {
                name: 'Sakthivel P',
                role: 'Event coordinator',
                team: 'Shibuya Survival Clash Ninjas',
                image: SakthivelP
            },
            {
                name: 'Suhail Ahmed T',
                role: 'Event coordinator',
                team: 'Shibuya Survival Clash Ninjas',
                image: SuhailAhmedT
            },
            {
                name: 'Siddheswari',
                role: 'Event coordinator',
                team: 'Lelouch\'s Cinematica Ninjas',
                image: Siddheswari
            },
            {
                name: 'Shanmathi',
                role: 'Event coordinator',
                team: 'Lelouch\'s Cinematica Ninjas',
                image: Shanmathi
            },
            {
                name: 'Sathasivam',
                role: 'Event coordinator',
                team: 'Lelouch\'s Cinematica Ninjas',
                image: Sathasivam
            },
            {
                name: 'Sabareesh C S',
                role: 'Event coordinator',
                team: 'Lelouch\'s Cinematica Ninjas',
                image: SabareeshCS
            },
            {
                name: 'Vaishak D',
                role: 'Event coordinator',
                team: 'Lelouch\'s Cinematica Ninjas',
                image: VaishakD
            },
            {
                name: 'Yuvabharathi',
                role: 'Event coordinator',
                team: 'Lelouch\'s Cinematica Ninjas',
                image: Yuvabharathi
            },
            {
                name: 'Harithra',
                role: 'Event coordinator',
                team: 'Forger\'s Mission Ninjas',
                image: Harithra
            },
            {
                name: 'Keerthana',
                role: 'Event coordinator',
                team: 'Forger\'s Mission Ninjas',
                image: Keerthana
            },
            {
                name: 'Pravikash',
                role: 'Event coordinator',
                team: 'Forger\'s Mission Ninjas',
                image: Pravikash
            },
            {
                name: 'Dharanidharan',
                role: 'Event coordinator',
                team: 'Forger\'s Mission Ninjas',
                image: Dharanidharan
            },
            {
                name: 'Ajitha Ananthi J',
                role: 'Event coordinator',
                team: 'Forger\'s Mission Ninjas',
                image: AjithaAnanthiJ
            },
            {
                name: 'Dharshini',
                role: 'Event coordinator',
                team: 'Ginwars: Ad Rumble Ninjas',
                image: DharshiniNew
            },
            {
                name: 'Naveen Karthick',
                role: 'Event coordinator',
                team: 'Ginwars: Ad Rumble Ninjas',
                image: NaveenKarthick
            },
            {
                name: 'Pavithra',
                role: 'Event coordinator',
                team: 'Ginwars: Ad Rumble Ninjas',
                image: PavithraAds
            },
            {
                name: 'Padmasree',
                role: 'Event coordinator',
                team: 'Ginwars: Ad Rumble Ninjas',
                image: Padmasree
            },
            {
                name: 'Sridhar R',
                role: 'Event coordinator',
                team: 'Ginwars: Ad Rumble Ninjas',
                image: SridharR
            },
            {
                name: 'Mugunthan',
                role: 'Event coordinator',
                team: 'Titan Tactics Ninjas',
                image: Mukunthan
            },
            {
                name: 'Esthak',
                role: 'Event coordinator',
                team: 'Titan Tactics Ninjas',
                image: Esthak
            },
            {
                name: 'Harsha',
                role: 'Event coordinator',
                team: 'Titan Tactics Ninjas',
                image: Harsha
            },
            {
                name: 'Sandhya',
                role: 'Event coordinator',
                team: 'Titan Tactics Ninjas',
                image: Sandhya
            },
            {
                name: 'Abisha Eunice',
                role: 'Event coordinator',
                team: 'Titan Tactics Ninjas',
                image: AbishaEunice
            }            
           
        ];
    },

    getTeamSmall() {
        return Promise.resolve(this.getTeamData().slice(0, 5));
    },

    getTeam() {
        return Promise.resolve(this.getTeamData());
    }
};
